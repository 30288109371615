// @flow
import React from 'react'
import cn from 'classnames'
import queryString from 'query-string'

import withTheme from 'hoc/withTheme'

import { fetchLead, patchLead } from 'Actions'
import { Store } from 'Store'

import styles from './styles.module.scss'

import themes, { type Theme } from './themes/__supportedThemes'

function Landing (props: { history: any, location: any, match: any, theme: Theme }) {
    const { dispatch } = React.useContext(Store)
    const [formState, setFormState] = React.useState(null)

    const handleSubmit = React.useCallback(async e => {
        e.preventDefault()

        const code = e.target.elements['code'].value

        if (!code) {
            alert('Please enter your code')
            return
        }

        setFormState('loading')

        try {
            let lead = await fetchLead(dispatch, code)
            lead = await patchLead(dispatch, code, {salesperson: lead.fields.salesperson }, true)

            let qs = queryString.stringify({ clientName: lead.fields.company, brand: lead.fields.currentVehicleMake })
            const response = await fetch(`/shift-marketing-playbook.pdf?${qs}`, {
                method: 'GET',
            })

            if (!response.ok) {
                console.error(await response.text())
                alert('Unable to download playbook.')
                return
            }

            const { url } = await response.json()

            window.location.replace(url)

            setFormState('submitted')
        } catch (err) {
            console.error(err)
            setFormState(null)
            return
        }
    }, [dispatch])

    return (
        <div className={styles.container}>
            <aside className={styles.leftSide}>
                <a className={styles.logoContainer} href='https://shiftmarketing.io/' target='_blank'
                   rel="noopener noreferrer">
                    <div className={styles.logo}/>
                </a>


                <div className={cn(styles.img, styles.img1)}/>
                <div className={cn(styles.img, styles.img2)}/>
                <div className={cn(styles.img, styles.img3)}/>
            </aside>

            <div className={styles.rightSide}>

                <div className={styles.logoMobile}>
                    <a className={styles.logoContainer} href='https://shiftmarketing.io/' target='_blank'
                       rel="noopener noreferrer">
                        <div className={styles.logo}/>
                    </a>
                </div>

                <h1 className={styles.title}>
                    Download your database marketing playbook today <span>(it's free!).</span>
                </h1>

                <form className={styles.form} onSubmit={handleSubmit}>
                    <input
                        name='code'
                        type="text"
                        placeholder="Enter your code"
                        defaultValue={queryString.parse(window.location.hash)['code']}
                    />
                    <button disabled={formState !== null}>
                        {formState === 'loading' ? 'Downloading...' : 'Download'}
                        <div className={styles.icon}/>
                    </button>
                </form>

                <div className={styles.information}>
                    <strong>What's inside:</strong>
                    <p>How brand loyalty and the digital transformation is changing database marketing.</p>
                    <p>Customer engagement results from 2020: mobile, desktop, email, text and mail.</p>
                    <p>Online customer journey enhancements.</p>
                    <p>Full year of database marketing suggestions and Shift One tools.</p>
                </div>


                <div className={styles.imgContainerMobile}>
                    <div className={cn(styles.img, styles.img1)}/>
                    <div className={cn(styles.img, styles.img2)}/>
                    <div className={cn(styles.img, styles.img3)}/>
                </div>

                <footer className={styles.footer}>
                    <span>
                        Shift Marketing Studio Inc. © 2020, All rights reserved.
                    </span>
                    <span>
                        Canada: PO Box 66031 Town Centre, Pickering, ON L1V6P7   |   203 - 1737 Union Street, Schenectady, NY 12309
                    </span>
                </footer>
            </div>
        </div>
    )
}

export default withTheme(themes)(Landing)
